import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';


import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartupService } from './startup.service';
import { AuthStorageService } from './common/auth-storage.service';



import { map } from 'rxjs/operators';
import { CommonModule } from './common/common.module';
import { RootRedirecterComponent } from './root-redirecter.component';
import { NotificationsInterceptor } from './notifications.interceptor';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';

const ngZorroConfig: NzConfig = {
  // empty: {
  //   nzDefaultEmptyContent: 'Нет данных'
  // }
};

export function loadCurrentUser(startupService: StartupService, authStorageService: AuthStorageService): Function {
  return () => startupService
      .getCurrentUser()
      .pipe(map(x => {
        authStorageService.setCurrentUser(x);
        return x;
      }))
      .toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    RootRedirecterComponent
  ],
  imports: [
    CommonModule,

    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,

    NzLayoutModule,
    NzMenuModule,
    NzNotificationModule,
    NzSpinModule,
    NzIconModule
  ],
  providers: [
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadCurrentUser,
      deps: [StartupService, AuthStorageService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationsInterceptor,
      multi: true
    },
    {
      provide: NZ_CONFIG,
      useValue: ngZorroConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
