<ng-template #globalNoDataContent>
  <nz-empty nzNotFoundImage="simple" nzNotFoundContent="Нет данных"></nz-empty>
</ng-template>

<nz-layout>
  <nz-header class="mrsu-main-header">
    <div class="logo">
      <img src="//s.tvurl.co/img/get/94d3bfd3-91f9-4558-9ece-aa80014eb666/CM-intl_(1).png"
           alt="CodeMastersIntl" class="img-crop">
    </div>

    <ul *ngIf="currentUser !== null" nz-menu nzTheme="dark" nzMode="horizontal" class="mrsu-header-menu">
      <li *ngIf="currentUser.rolesMap.TEACHER" nz-menu-item nzMatchRouter>
        <a [routerLink]="['/learning']">Обучение</a>
      </li>
      <li *ngIf="currentUser.rolesMap.ADMIN" nz-menu-item nzMatchRouter>
        <a [routerLink]="['/admin']">Управление</a>
      </li>
      <li *ngIf="currentUser.rolesMap.STUDENT" nz-menu-item nzMatchRouter>
        <a [routerLink]="['/personal']">Личный Кабинет</a>
      </li>
    </ul>

    <div class="mrsu-filler"></div>

    <section *ngIf="currentUser" class="mrsu-current-user">
      <span class="mrsu-current-user-name">
        <app-person-avatar class="mrsu-avatar" [model]="currentUser"></app-person-avatar>
        {{currentUser.fullName}}
      </span>

      <a (click)="signOut()">Выйти</a>
    </section>
  </nz-header>

  <nz-content class="mrsu-outer-content">
    <div class="mrsu-scrollable-container">
      <section *ngIf="loadingRouteConfig"
               style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: flex; align-items: center; justify-content: center;">

        <nz-spin nzSimple></nz-spin>
      </section>

      <div style="padding: 0 50px;">
        <h1 class="mrsu-main-title">Портал школы разработчиков</h1>

        <router-outlet></router-outlet>
      </div>
    </div>
  </nz-content>

  <nz-footer>©2019-{{todayYear}} Codemasters International</nz-footer>
</nz-layout>
