import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootRedirecterComponent } from './root-redirecter.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: RootRedirecterComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
  },
  {
    path: 'learning',
    loadChildren: () => import('./dashboard/dashboard.module').then(module => module.AdminModule),
    data: {
      breadcrumb: 'Обучение'
    }
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(module => module.AdminModule),
    data: {
      breadcrumb: 'Управление'
    }
  },
  {
    path: 'personal',
    loadChildren: () => import('./personal/personal.module').then(module => module.PersonalModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
