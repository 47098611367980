import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { OPTIONAL_CURRENT_USER_MAPPER } from './common/current-user.mapper';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(private http: HttpClient) { }

  getCurrentUser() {
    return this.http
      .get('api/account/me')
      .pipe(
        map(OPTIONAL_CURRENT_USER_MAPPER));
  }
}
