import { PersonWithRoles, UserRole } from './domain/person';

export class CurrentUser extends PersonWithRoles  {
  constructor(
    id: number,
    email: string,
    public readonly username: string,
    public firstName: string,
    public lastName: string,
    roles: UserRole[]) {

    super(id, email, firstName, lastName, roles);
  }
}
