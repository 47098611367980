import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

import { AuthStorageService } from './auth-storage.service';
import { AccountService } from './account.service';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { RouterModule } from '@angular/router';
import { PersonAvatarComponent } from './components/person-avatar/person-avatar.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { PeopleAvatarsComponent } from './components/people-avatars/people-avatars.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RoleLabelComponent } from './components/role-label/role-label.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@NgModule({
  declarations: [BreadcrumbComponent, PersonAvatarComponent, PeopleAvatarsComponent, RoleLabelComponent],
  imports: [
    AngularCommonModule,
    RouterModule,

    NzBreadCrumbModule,
    NzButtonModule,
    NzAvatarModule,
    NzToolTipModule,
    NzTagModule,
    NzEmptyModule,
  ],
  providers: [
  ],
  exports: [
    BreadcrumbComponent,
    PersonAvatarComponent,
    PeopleAvatarsComponent,
    RoleLabelComponent,
    NzAvatarModule,
    NzTagModule,
    NzEmptyModule,
    NzToolTipModule
  ]
})
export class CommonModule { }
