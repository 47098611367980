import { Component, Input } from '@angular/core';
import { PersonRef } from '../../domain/personRef';

@Component({
  selector: 'app-person-avatar',
  templateUrl: './person-avatar.component.html',
  styleUrls: ['./person-avatar.component.less']
})
export class PersonAvatarComponent {
  @Input()
  public size: 'large' | 'small' | 'default' | number = 'default';

  @Input()
  public model!: PersonRef;
}
