import { PersonRef } from './personRef';

export enum UserRole {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  Admin = 'ADMIN'
}

export class Person extends PersonRef {
  public readonly fullName = this.firstName + ' ' + this.lastName;

  constructor(
    id: number,
    email: string,
    public readonly firstName: string,
    public readonly lastName: string) {

    super(id, email);
  }
}

export class CourseSessionStudent extends Person {
  constructor(
    id: number,
    email: string,
    firstName: string,
    lastName: string,
    public readonly studentCourseSessionId: number) {
    super(id, email, firstName, lastName);
  }
}

type RolesMap = { [index in UserRole]?: true };

export class PersonWithRoles extends Person {
  public readonly rolesMap: RolesMap = this.roles.reduce((acc, role) => {
    acc[role] = true;
    return acc;
  }, {} as RolesMap);

  constructor(
    id: number,
    email: string,
    firstName: string,
    lastName: string,
    public readonly roles: UserRole[]) {
    super(id, email, firstName, lastName);
  }
}
