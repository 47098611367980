import { Injectable } from '@angular/core';
import { CurrentUser } from "./current-user";
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {
  private _currentUserObservable = new BehaviorSubject<CurrentUser | null>(null);

  public setCurrentUser(value: CurrentUser | null) {
    this._currentUserObservable.next(value);
  }

  public getCurrentUser() {
    return this._currentUserObservable.getValue();
  }

  public getStream(): Observable<CurrentUser | null> {
    return this._currentUserObservable;
  }
}
