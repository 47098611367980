import { RefObject } from './ref-object';
import { MD5 } from '../utils/md5';

export class PersonRef extends RefObject {
  public readonly emailHash = MD5(this.email);

  constructor(
    id: number,
    public readonly email: string) {
    super(id);
  }
}
