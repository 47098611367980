import { CurrentUser } from "./current-user";

export const CURRENT_USER_MAPPER = (dto: any) => {
  return new CurrentUser(
    dto.id,
    dto.email,
    dto.username,
    dto.firstName,
    dto.lastName,
    dto.roles);
};

export const OPTIONAL_CURRENT_USER_MAPPER = (dto: any) => {
  if (!dto) {
    return null;
  }

  return CURRENT_USER_MAPPER(dto);
};
