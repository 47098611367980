import { Component, OnInit } from '@angular/core';
import { AuthStorageService } from './common/auth-storage.service';
import { Router } from '@angular/router';
import { CurrentUser } from './common/current-user';

@Component({
  template: ''
})
export class RootRedirecterComponent implements OnInit {
  constructor(
    private authStorageService: AuthStorageService,
    private router: Router) {
  }

  ngOnInit(): void {
    const currentUser = this.authStorageService.getCurrentUser();
    const redirectTarget = this.getRedirectTarget(currentUser!);

    this.router.navigate(['/' + redirectTarget]);
  }

  private getRedirectTarget(currentUser: CurrentUser) {
    if (currentUser === null) {
      return 'auth/login';
    }

    if (currentUser.rolesMap.TEACHER) {
      return 'learning';
    }

    if (currentUser.rolesMap.ADMIN) {
      return 'admin';
    }

    return 'personal';
  }
}
