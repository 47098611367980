import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { StartupService } from './startup.service';
import { AuthStorageService } from './common/auth-storage.service';
import { CurrentUser } from './common/current-user';
import { Subscription } from 'rxjs';
import { AccountService } from './common/account.service';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { NzConfigService } from 'ng-zorro-antd/core/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
  private _authSubscription: Subscription = Subscription.EMPTY;

  public currentUser: CurrentUser | null = null;
  public loadingRouteConfig = false;
  public readonly todayYear: string = new Date().getFullYear().toString();

  @ViewChild('globalNoDataContent', { static: true })
  globalNoDataContent!: TemplateRef<string>;

  constructor(
    startupService: StartupService,
    private authStorageService: AuthStorageService,
    private accountService: AccountService,
    private router: Router,
    private readonly _nzConfigService: NzConfigService) {
  }

  ngOnInit(): void {
    this._nzConfigService.set(
      'empty',
      {
        nzDefaultEmptyContent: this.globalNoDataContent
      });

    this._authSubscription = this.authStorageService
      .getStream()
      .subscribe(x => {
        this.currentUser = x;
      });

    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    });
  }

  ngOnDestroy(): void {
     this._authSubscription.unsubscribe();
  }

  signOut() {
    this.accountService
      .signOut()
      .subscribe(() => {
        this.authStorageService.setCurrentUser(null);
        this.router.navigate(['/']);
      });
  }
}
