import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CURRENT_USER_MAPPER } from '../common/current-user.mapper';
import { OperationResult, OperationResultOf } from './operation-result';
import { CurrentUser } from './current-user';

export interface CreateAdminForm {
  firstName: string; // todo extract common fields
  lastName: string;
  email: string;
  phoneNumber: string;

  username: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private http: HttpClient) {
  }

  createAdmin(form: CreateAdminForm) {
    return this.http.post<OperationResult>('api/account/admin/create', form);
  }

  createDefaultAdmin(form: CreateAdminForm) {
    return this.http.post<OperationResult>('api/account/admin/default', form);
  }

  pendingInitialSetup() {
    return this.http.get<boolean>('api/account/pending-setup');
  }

  signIn(username: string, password: string) {
    return this.http
      .post<OperationResultOf<any>>('api/account/signin', { username, password })
      .pipe(
        map((result: OperationResultOf<any>) => {
          const transformedResult: OperationResultOf<CurrentUser> = {
            isSuccess: result.isSuccess,
            messages: result.messages,
            result: CURRENT_USER_MAPPER(result.result)
          };

          return transformedResult;
        }));
  }

  public signOut() {
    return this.http.post('api/account/signout', {});
  }
}
